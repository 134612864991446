.datatables-table .rdt_TableRow {
  min-height: 33px;
  font-size: 13.5px;
}

.datatables-table .rdt_TableCol_Sortable span {
  font-size: 9px;
  margin-top: 2px;
  margin-left: 2px;

  color: #6c757d !important;
}

.datatables-table .rdt_TableHeader {
  min-height: 48px;
  border-top: 1px solid #dee2e6 !important;
}

.datatables-table .rdt_TableHeader div {
  background: #fafafa;
}

.datatables-table .rdt_TableRow:hover {
  background-color: rgba(44, 69, 147, 0.1);

  border-bottom-color: #dee2e6 !important;
  outline: none !important;
}

.datatables-table .rdt_TableHeadRow {
  font-size: 0.8rem;
  min-height: 28px;

  background-color: #f6f6f6;

  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #cccccc;
}

.datatables-table .rdt_TableCol {
  font-size: 13px;
  font-weight: normal;

  border-right: 1px solid #dee2e6;
}

.datatables-table .rdt_TableCol:last-child {
  border-right: none;
}
