/** */
.swal2-container .swal2-popup.swal2-toast {
  padding: 16px 20px;
  border-radius: 2px;
  background-color: var(--color-black);
  color: white;
  border: solid 1px var(--color-black);
  box-shadow: 0 0 0.25em -1px var(--color-black);
  height: 49px;
}

.swal2-container .swal2-popup.swal2-toast .swal2-title {
  color: inherit;
  font-size: 0.9em;
  font-weight: normal;
  margin-right: 20px;
}

.swal2-container .swal2-popup.swal2-toast .swal2-close {
  color: inherit;
  opacity: 0.6;
  font-size: 1em;
  font-weight: bold;
}

.swal2-container .swal2-popup.swal2-toast .swal2-close:hover {
  color: inherit;
  opacity: 0.8;
}

.swal2-container .swal2-popup.swal2-toast .swal2-icon {
  display: none !important;
}

/** COLORS */
.swal2-container .swal2-popup.swal2-toast.swal2-icon-success {
  background-color: white;
  color: var(--color-green);
  box-shadow: 0 0 0.25em -1px var(--color-green);
  border-color: var(--color-green);
}
.swal2-container .swal2-popup.swal2-toast.swal2-icon-error {
  background-color: white;
  color: red;
  box-shadow: 0 0 0.25em -1px red;
  border-color: red;
}
.swal2-container .swal2-popup.swal2-toast.swal2-icon-warning {
  background-color: white;
  color: var(--color-yellow);
  box-shadow: 0 0 0.25em -1px var(--color-yellow);
  border-color: var(--color-yellow);
}
/** */

/** TRANSITION EFFECT */
body .swal2-container.swal2-backdrop-show {
  animation: movedown 0.4s ease-out;
  padding: 32px;
}
body .swal2-container.swal2-backdrop-show .swal2-show {
  animation: none;
  transition: opacity ease-out 0.4s;
}
body .swal2-container.swal2-backdrop-hide .swal2-hide {
  animation: swal2-toast-hide-2 0.4s;
}
body .swal2-container.swal2-backdrop-hide {
  animation: moveup 0.4s ease-in;
  padding: 32px;
}

@keyframes movedown {
  from {
    top: -48px;
  }
  to {
    top: 0px;
  }
}
@keyframes moveup {
  from {
    top: 0px;
  }
  to {
    top: -48px;
  }
}

@keyframes swal2-toast-hide-2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
